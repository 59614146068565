import logo from '../../assets/logo/LOGO - CONOB_Prancheta_zoom-800.png';
import './Footer.css';
import {NavLink, useLocation} from "react-router-dom";
import React from "react";
function Footer() {
    const location = useLocation();

    return (

      <footer class="row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5  border-top bg-dark text-white text-start">
        <div class="col mb-3">
        <a className="navbar-brand " href="#">
            <img src={logo} alt="Logo" className="logo-navbar" loading='lazy' width={200}/>
          </a>
          <p class="text-white">&copy; 2015</p>
          <p class="text-white"><a href="https://github.com/GreyPompom">
          <i class="bi bi-code-slash "></i> Made by Hemelink</a></p>
        </div>

        <div class="col mb-3">

        </div>

        <div class="col mb-3 ">
          <h5>Empresa</h5>
          <ul class="nav flex-column">
           <li class="nav-item mb-2"><NavLink
               exact
               to="/"
               className={`nav-link p-0 text-white ${location.pathname === '/' ? 'active' : ''}`}
               aria-current={location.pathname === '/' ? 'page' : undefined}
           >
               Home
           </NavLink></li>
            <li class="nav-item mb-2"> <NavLink
                to="/sobre-nos"
                className={`nav-link p-0 text-white ${location.pathname === '/sobre' ? 'active' : ''}`}
                aria-current={location.pathname === '/sobre' ? 'page' : undefined}>
                Sobre nós
            </NavLink></li>
            <li class="nav-item mb-2">
                <NavLink
                to="/sistemas"
                className={`nav-link p-0 text-white ${location.pathname === '/sistemas' ? 'active' : ''}`}
                aria-current={location.pathname === '/sistemas' ? 'page' : undefined}>
                Nosos Sistemas
            </NavLink></li>
            <li class="nav-item mb-2"><NavLink
                to="/entre-contato"
                className={`nav-link p-0 text-white ${location.pathname === '/entre-contato' ? 'active' : ''}`}
                aria-current={location.pathname === '/entre-contato' ? 'page' : undefined}>
                Entre em contato
            </NavLink></li>
           
          </ul>
        </div>

        <div class="col mb-3">
          <h5>Suporte</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-2"><NavLink
                to="/perguntas-frequentes"
                className={`nav-link p-0 text-white ${location.pathname === '/perguntas-frequentes' ? 'active' : ''}`}
                aria-current={location.pathname === '/perguntas-frequentes' ? 'page' : undefined}>
                Perguntas frequentes
            </NavLink></li>
            <li class="nav-item mb-2"><NavLink
                to="/politica"
                className={`nav-link p-0 text-white ${location.pathname === '/termos-servicos' ? 'active' : ''}`}
                aria-current={location.pathname === '/termos-servicos' ? 'page' : undefined}>
                Termos de serviço
            </NavLink></li>
            <li class="nav-item mb-2"><NavLink
                to="/politica"
                className={`nav-link p-0 text-white ${location.pathname === '/politica-privacidade' ? 'active' : ''}`}
                aria-current={location.pathname === '/politica-privacidade' ? 'page' : undefined}>
                Política de privacidade
            </NavLink></li>
          </ul>
        </div>
      </footer>
  );
}

export default Footer;
